const mixin = {
  data() {
    return {
      isFullScreen: false,
    };
  },
  mounted() {
    if (this.fullScreenElId) this.fullScreenEl = document.getElementById(this.fullScreenElId);
    // 监听全屏事件
    document.addEventListener('fullscreenchange', this.switchScreenStatus, false);
    document.addEventListener('webkitfullscreenchange', this.switchScreenStatus, false);
    document.addEventListener('mozfullscreenchange', this.switchScreenStatus, false);
    document.addEventListener('MSFullscreenChange', this.switchScreenStatus, false);
  },
  destroyed() {
    clearInterval(this.dataTimer);
    // 监听全屏事件
    document.removeEventListener('fullscreenchange', this.switchScreenStatus, false);
    document.removeEventListener('webkitfullscreenchange', this.switchScreenStatus, false);
    document.removeEventListener('mozfullscreenchange', this.switchScreenStatus, false);
    document.removeEventListener('MSFullscreenChange', this.switchScreenStatus, false);
  },
  methods: {
    switchScreenStatus() {
      this.isFullScreen = !this.isFullScreen;
      this.fullScreenCallBack && this.fullScreenCallBack();
    },
    // 全屏
    handleFullScreen() {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        launchFullScreen(
          this.fullScreenElId
            ? document.getElementById(this.fullScreenElId)
            : document.documentElement
        );
      } else {
        var exitMethod =
          document.exitFullscreen || // W3C
          document.mozCancelFullScreen || // FireFox
          document.webkitExitFullscreen || // Chrome等
          document.msExitFullscreen; // IE11
        if (exitMethod) {
          cancelFullScreen();
        }
      }
      function cancelFullScreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
      function launchFullScreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      }
    },
  },
};

export default mixin;
