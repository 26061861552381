<template>
  <div class="merchant-data-shop bffffff">
    <div class="storey-title between">
      <div class="left">店铺数据</div>
      <div class="right between">
        <span>
          统计时间：{{ $_.get(shopData, 'base.start_time') }}-{{
            $_.get(shopData, 'base.end_time')
          }}
        </span>
        <div class="exchange-btns flex">
          <el-button
            plain
            size="small"
            class="btn button-plain--default"
            :class="{ active: activeDataIndex === index }"
            v-for="(item, index) in dataTypeOptions"
            :key="item.id"
            @click="exchange(index, item.type === 'normal')"
          >
            {{ item.label }}
            <el-date-picker
              v-model="item.model"
              :type="item.type"
              v-if="item.type !== 'normal'"
              :picker-options="{ firstDayOfWeek: item.type === 'week' ? 1 : 7 }"
              @change="exchange(index, true)"
            ></el-date-picker>
          </el-button>
        </div>
      </div>
    </div>
    <div class="data-module">
      <div class="title-medium">核心数据</div>
      <div class="data-list">
        <div v-for="item in base" :key="item.label" class="data-item-1">
          <i class="iconfont" :class="`icon-${item.icon}`"></i>
          <span class="label">{{ item.label }}</span>
          <span class="value">{{ $_.get(shopData.base, item.prop) }}</span>
        </div>
      </div>
    </div>
    <div class="data-module mb16">
      <div class="title-medium">渠道来源占比</div>
      <div class="flex">
        <div class="channel-chart">
          <div class="no-data" v-show="channelNoData">
            暂无数据
          </div>
          <div class="chart-wrap">
            <div id="channel" :style="{ height: '136px' }"></div>
          </div>
          <div class="btns">
            <el-button
              plain
              size="small"
              class="btn button-plain--default"
              :class="{ active: activeChannelIndex === index }"
              v-for="(item, index) in channelTypeOptions"
              :key="item.id"
              @click="activeChannelIndex = index"
            >
              {{ item.label }}
            </el-button>
          </div>
        </div>
        <div class="data-list channel-list flex-all">
          <div class="no-data" v-show="channelNoData">
            暂无数据
          </div>
          <div v-for="item in sourceData" :key="item.label" class="data-item-2">
            <div class="data-item-content">
              <span class="text">{{ item.name }}</span>
              <span class="value">{{ item.value }}</span>
              <span class="text">占比</span>
              <span class="percent">{{ item.percent }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-module sale-module">
      <div class="title-medium">销售额走势图</div>
      <div class="sale-chart" style="height:300px;">
        <div class="no-data" v-show="saleDateNoData">
          暂无数据
        </div>
        <div id="sale" style="width:100%;height:100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
import merchantConfig from '@/data/merchantData';
import { Chart } from '@antv/g2';
export default {
  props: {
    information: {
      type: Object,
    },
  },
  data() {
    return {
      dataTypeOptions: merchantConfig.shop.dataType,
      channelTypeOptions: merchantConfig.shop.channelType,
      activeDataIndex: 1,
      activeChannelIndex: 0,
      shopData: {},
      channelChart: null,
      saleChart: null,
    };
  },
  computed: {
    base() {
      return [
        {
          prop: 'all_total_amount',
          icon: 'xiaoshoue11',
          label: '销售额（元）',
        },
        {
          prop: 'all_order_count',
          icon: 'chengjiaodingdanshu11',
          label: '成交订单数',
        },
        {
          prop: 'send_order_count',
          icon: 'yifahuodingdanshu11',
          label: '已发货订单数',
        },
        {
          prop: 'refund_order_count',
          icon: 'faqishouhoudingdanshu11',
          label: '发起售后订单数',
        },
        {
          prop: 'add_member_count',
          icon: 'xinzengyonghushu11',
          label: '新增用户数',
        },
      ];
    },
    shopDataType() {
      return this.dataTypeOptions[this.activeDataIndex].value;
    },
    sourceDataType() {
      return this.channelTypeOptions[this.activeChannelIndex].value;
    },
    sourceData() {
      const data = this.$_.get(this.shopData.source_than, this.sourceDataType, {});
      const arr = Object.keys(data).reduce((acc, key) => {
        const v = data[key];
        const name =
          this.shopSourceObj[key].source_name +
          this.channelTypeOptions[this.activeChannelIndex].title;
        acc.push({
          name,
          value: v.order_count || v.total_amount,
          percent: v.total_amount_than || v.order_count_than,
        });
        return acc;
      }, []);
      return arr;
    },
    shopSourceObj() {
      return this.$store.state.shopSourceObj;
    },
    channelNoData() {
      const data = this.$_.get(this.shopData.source_than, this.sourceDataType, {});
      return JSON.stringify(data) === '[]';
    },
    saleDateNoData() {
      const data = this.$_.get(this.shopData, 'sales_date', []);
      return JSON.stringify(data) === '[]';
    },
    merchantType() {
      return this.$store.state.merchantType;
    },
  },
  watch: {
    activeChannelIndex() {
      this.drawChannel();
    },
    'information.shopNow.id': {
      handler(newValue) {
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
    window.onresize = this.$_.debounce(this.handleResize, 100);
  },
  destroyed() {
    window.onresize = null;
  },
  methods: {
    handleResize() {
      // this.drawSale(true);
    },
    getData() {
      const { model, type } = this.dataTypeOptions[this.activeDataIndex];
      if (!model && type !== 'normal') return;
      const date_begin_time = model ? this.$moment(model).unix() : '';

      this.$get({
        road: this.merchantType === 0 ? 'brainShop' : 'jybrainShop',
        query: {
          shop_id:
            this.merchantType === 0
              ? this.information.shopNow.id
              : this.information.shopNow.shop_id,
          date_type: this.shopDataType,
          date_begin_time,
        },
        version: 'v0',
      }).then(res => {
        if (res.error_code === 0) {
          const data = res.data;
          this.shopData = data;
          Object.keys(data.source_than);
          this.$nextTick(() => {
            this.drawChannel();
            this.drawSale();
          });
        }
      });
    },
    exchange(index, sym) {
      if (sym) {
        this.activeDataIndex = index;
        this.$nextTick(() => {
          this.getData();
        });
      }
    },
    drawChannel() {
      const self = this;
      const data = this.$_.get(this.shopData.source_than, this.sourceDataType, {});
      const arr = Object.keys(data).reduce((acc, key) => {
        const v = data[key];
        const name = this.shopSourceObj[key].source_name;
        acc.push({
          name,
          value: parseFloat((v.total_amount_than || v.order_count_than).slice(0, -1)),
        });
        return acc;
      }, []);
      if (this.channelChart) {
        this.channelChart.clear(); // 清理所有图形
        this.channelChart.destroy();
      }
      this.channelChart = new Chart({
        container: 'channel',
        autoFit: true,
        height: 500,
      });

      this.channelChart.coordinate('theta', {
        radius: 0.85,
        innerRadius: 0.7,
      });

      this.channelChart.data(arr);

      this.channelChart.scale('value', {
        formatter: val => {
          val = val + '%';
          return val;
        },
      });

      this.channelChart.tooltip(true);

      // 声明需要进行自定义图例字段： 'item'
      this.channelChart.legend('name', {
        position: 'right', // 配置图例显示位置
      });

      this.channelChart
        .interval()
        .adjust('stack')
        .position('value')
        .color('name')
        .style({
          fillOpacity: 1,
        })
        .state({
          active: {
            style: element => {
              const shape = element.shape;
              return {
                lineWidth: 5,
                stroke: shape.attr('fill'),
                strokeOpacity: shape.attr('fillOpacity'),
              };
            },
          },
        });

      // 移除图例点击过滤交互
      this.channelChart.removeInteraction('legend-filter');
      this.channelChart.interaction('element-active');

      this.channelChart.render();

      // 监听 element 上状态的变化来动态更新 Annotation 信息
      this.channelChart.on('element:statechange', ev => {
        const { state, stateStatus, element } = ev.gEvent.originalEvent;

        // 本示例只需要监听 active 的状态变化
        if (state === 'active') {
          const data = element.getData();
          if (stateStatus) {
            // 更新 Annotation
            updateAnnotation(data);
          } else {
            // 隐藏 Annotation
            clearAnnotation();
          }
        }
      });

      // 绘制 annotation
      let lastItem;
      function updateAnnotation(data) {
        if (data.name !== lastItem) {
          self.channelChart.annotation().clear(true);
          self.channelChart.annotation().text({
            position: ['50%', '50%'],
            content: data.name,
            style: {
              fontSize: 20,
              fill: '#8c8c8c',
              textAlign: 'center',
            },
            offsetY: 0,
          });
          self.channelChart.render(true);
          lastItem = data.name;
        }
      }

      // 清空 annotation
      function clearAnnotation() {
        self.channelChart.annotation().clear(true);
        self.channelChart.render(true);
        lastItem = null;
      }
    },
    drawSale() {
      const data = this.$_.get(this.shopData, 'sales_date', {});
      // 修复数据为空图表显示问题
      const dateKey = this.$moment(this.dataTypeOptions[this.activeDataIndex].model).format(
        'MM/DD'
      );
      if (data.length === 0 && this.shopDataType === 5) {
        data[dateKey] = '0.00';
      }

      const chartData = [];
      Object.keys(data).forEach(key => {
        chartData.push({
          day: key,
          value: parseFloat(data[key]),
        });
      });
      if (this.saleChart) {
        this.saleChart.clear(); // 清理所有图形
        this.saleChart.destroy();
      }

      this.saleChart = new Chart({
        container: 'sale',
        autoFit: true,
        height: 500,
      });

      this.saleChart.data(chartData);
      this.saleChart.scale({
        day: {
          nice: true,
        },
        value: {
          alias: '销售额',
          min: 0,
          nice: true,
        },
      });

      this.saleChart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
      });
      if ([0, 4].includes(this.activeDataIndex)) {
        this.saleChart
          .interval()
          .position('day*value')
          .size(40);
      } else {
        this.saleChart
          .line()
          .position('day*value')
          // .label('value')
          .shape('smooth');

        this.saleChart.point().position('day*value');
      }

      this.saleChart.render();
    },
  },
};
</script>

<style lang="less" scoped>
.merchant-data-shop {
  margin-bottom: 20px;
}
.channel-chart {
  padding: 16px;
  border: 1px solid @border-color-main;
  display: flex;
  width: 534px;
  margin-right: 16px;
  .chart-wrap {
    flex: 1;
  }
  .btns {
    width: 160px;
    margin-left: 20px;
  }
}
.sale-module {
  padding: 0 24px;
  border: 1px solid @border-color-main;
  .title-medium {
    margin-bottom: 8px;
  }
}
.channel-list {
  border: 1px solid @border-color-main;
}
.icon-xiaoshoue11,
.icon-yifahuodingdanshu11 {
  color: #ffba32;
}
.icon-chengjiaodingdanshu11,
.icon-xinzengyonghushu11 {
  color: #6abbfe;
}
.icon-faqishouhoudingdanshu11 {
  color: #f8a045;
}
.channel-chart,
.channel-list,
.sale-chart {
  position: relative;
}
#sale > div {
  width: 100% !important;
}
</style>
