<template>
  <el-dialog
    width="800px"
    class="common-dialog"
    :title="extra.title"
    :center="extra.center"
    :visible.sync="visible"
    :destroy-on-close="true"
    :lock-scroll="false"
    append-to-body
    :before-close="handleClose"
  >
    <el-alert
      style="transform: translateY(-20px);"
      :title="'审核不通过原因：' + formData.reason"
      type="error"
      v-if="extra.type === 'againAdd'"
    ></el-alert>
    <el-form :model="formData" :rules="rules" ref="form" label-width="100px">
      <el-form-item label="商品名称" prop="product_name">
        <el-input
          v-model="formData.product_name"
          :disabled="extra.type === 'view' ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品链接" prop="product_url">
        <el-input
          v-model="formData.product_url"
          placeholder="请填写快店或抖店的商品链接"
          :disabled="extra.type === 'view' ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <Upload
          v-if="extra.type === 'view' ? false : true"
          :fileContent="formData.id"
          @addSuccess="addSuccess"
          :max="5"
          :nowImages="$_.get(formData, 'img', [])"
        />
        <ImgList :list.sync="formData.img" :selectIndex.sync="selectIndex"></ImgList>
      </el-form-item>
      <el-form-item label="品牌" prop="brand_name">
        <el-input
          v-model="formData.brand_name"
          :disabled="extra.type === 'view' ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="类目" prop="cp_category_id">
        <el-select
          v-model="formData.cp_category_id"
          placeholder="请选择"
          :disabled="extra.type === 'view' ? true : false"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="佣金和价格">
        <el-row>
          <el-col :span="10">
            <el-form-item label="全网销量（近一年）" prop="sale_num">
              <el-input
                v-model.number="formData.sale_num"
                :disabled="extra.type === 'view' ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="建议零售价（划线价）" prop="show_price">
              <el-input
                v-model.number="formData.show_price"
                :disabled="extra.type === 'view' ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="直播价（售价）" prop="price">
              <el-input
                v-model.number="formData.price"
                :disabled="extra.type === 'view' ? true : false"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="佣金比例" prop="rate">
              <el-input
                v-model.number="formData.rate"
                type="number"
                :disabled="extra.type === 'view' ? true : false"
              ></el-input>
              <span style="position:absolute">%</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="佣金" prop="rate_price">
              <span>{{ rate_price }}</span>
              <!-- <el-input v-model="formData.rate_price" :disabled="true"></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="form-btns-wrap" v-if="extra.type === 'view' ? false : true">
        <el-button :loading="submiting" type="primary" @click="onSubmit">确定</el-button>
        <el-button plain @click="handleClose">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import DialogMixin from '@/mixin/dialog';
export default {
  name: 'GoodsEdit',
  mixins: [DialogMixin],
  data() {
    return {
      checkResult: [],
      formData: {},
      selectIndex: 0,
      rules: {
        product_name: [{ required: true, message: '请输入商品标题', trigger: 'blur' }],
        brand_name: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        cp_category_id: [{ required: true, message: '请选择类目', trigger: 'change' }],
        img: [
          {
            type: 'array',
            min: 1,
            required: true,
            message: '请上传图片',
            trigger: 'change',
          },
        ],
        sale_num: [
          {
            required: true,
            message: '请填写销量',
            trigger: 'blur',
            // validator: (rule, value, callback) => {
            //   if (value >= 500) {
            //     callback();
            //   } else {
            //     callback(new Error('请输入大于等于500的销量'));
            //   }
            // },
          },
        ],
        product_url: [{ required: true, message: '请填写商品链接', trigger: 'blur' }],
        show_price: [{ required: true, message: '请填写建议零售价', trigger: 'blur' }],
        price: [{ required: true, message: '请填写直播价', trigger: 'blur' }],
        rate: [{ required: true, message: '请填写佣金比例', trigger: 'blur' }],
        // rate_price: [{ required: true, message: '请填写直播价', trigger: 'blur' }],
      },
    };
  },
  watch: {
    'extra.formData': {
      handler(newVal, oldVal) {
        this.formData = JSON.parse(JSON.stringify(newVal));
        this.formData.rate_price = this.formData.rate_price / 100;
        this.formData.show_price = this.formData.show_price / 100;
        this.formData.price = this.formData.price / 100;
        this.formData.cp_category_id = this.formData.cp_category_id
          ? Number(this.formData.cp_category_id)
          : null;
      },
      deep: true,
    },
  },
  computed: {
    options() {
      return this.$store.state.selectPoolGoodsCategory;
    },
    rate_price() {
      return this.formData.price && this.formData.rate
        ? (this.formData.price * this.formData.rate) / 100
        : 0;
    },
  },
  methods: {
    closeCallBack() {},
    addSuccess(fileName) {
      this.formData.img.push({ img: fileName, is_main: 0 });
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submiting = true;
          const params = JSON.parse(JSON.stringify(this.formData));
          params.rate_price = params.rate_price * 100;
          params.show_price = params.show_price * 100;
          params.price = params.price * 100;
          params.rate_price = this.rate_price;
          params.pic = params.img[this.selectIndex].img;
          // params.img = params.img.map(function(item, index) {
          //   return { img: item, is_main: 0 };
          // });
          params.img[this.selectIndex].is_main = 1;
          const data = await this.$post({
            road: 'cpProductAdd',
            data: params,
            version: 'v0',
          }).then(res => res.error_code === 0);
          if (data) {
            this.$message.success('商品提交成功');
            this.onOk && this.onOk();
            this.handleClose();
          }
          this.submiting = false;
        }
      });
    },
  },
};
</script>

<style lang="less">
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
