export default {
  shop: {
    dataType: [
      {
        value: 1,
        label: '今日',
        type: 'normal',
      },
      {
        value: 2,
        label: '近7日',
        type: 'normal',
      },
      {
        value: 3,
        label: '近30日',
        type: 'normal',
      },
      {
        value: 4,
        label: '近90日',
        type: 'normal',
      },
      {
        value: 5,
        label: '自然日',
        type: 'date',
        model: '',
      },
      {
        value: 6,
        label: '自然周',
        type: 'week',
        model: '',
      },
      {
        value: 7,
        label: '自然月',
        type: 'month',
        model: '',
      },
      {
        value: 8,
        label: '累计',
        type: 'normal',
      },
    ],
    channelType: [
      {
        value: 'source_by_sales',
        label: '销售额',
        title: '销售额（元）',
      },
      {
        value: 'source_by_order',
        label: '订单数',
        title: '订单数',
      },
    ],
  },
  goods: {
    dataType: [
      {
        value: 1,
        label: '今日',
        type: 'normal',
      },
      {
        value: 2,
        label: '近7日',
        type: 'normal',
      },
      {
        value: 3,
        label: '近30日',
        type: 'normal',
      },
      {
        value: 4,
        label: '近90日',
        type: 'normal',
      },
      {
        value: 5,
        label: '自然日',
        type: 'date',
        model: '',
      },
      {
        value: 6,
        label: '自然周',
        type: 'week',
        model: '',
      },
      {
        value: 7,
        label: '自然月',
        type: 'month',
        model: '',
      },
      {
        value: 8,
        label: '累计',
        type: 'normal',
      },
    ],
    salesType: [
      {
        value: 'all',
        label: '汇总',
      },
      {
        value: 'DouYin',
        label: '抖音',
      },
      {
        value: 'KuaiShou',
        label: '快手',
      },
    ],
  },
};
