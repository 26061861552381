<template>
  <div class="merchant-data-wrap" id="merchant-data">
    <div class="merchant-data-main" v-if="$_.get(information, 'shopNow.id')">
      <Header
        :isFullScreen="isFullScreen"
        :handleFullScreen="handleFullScreen"
        :information="information"
        :shopChange="shopChange"
      />
      <ShopData :information="information" />
      <GoodsData :information="information" />
    </div>
    <div style="height: 150px" v-loading="true" v-else></div>
  </div>
</template>

<script>
import fullScreenMixin from '@/mixin/fullScreen';
import listPageMixin from '@/mixin/listPage';
import Header from '@/modules/brand/merchantData/header';
import ShopData from '@/modules/brand/merchantData/shopData';
import GoodsData from '@/modules/brand/merchantData/goodsData';
export default {
  components: { Header, ShopData, GoodsData },
  mixins: [listPageMixin, fullScreenMixin],
  data() {
    return {
      fullScreenElId: 'merchant-data',
      information: {},
    };
  },
  created() {
    this.getShopList();
    // if (!this.merchantType) {
    //   this.$store.dispatch('getAccountInfo');
    // }
  },
  computed: {
    merchantType() {
      return this.$store.state.merchantType;
    },
  },

  methods: {
    getShopList() {
      const self = this;
      this.$get({
        road: this.merchantType === 0 ? 'getShops' : 'jygetShops',
        query: { phone: this.$route.params.id },
        version: 'v0',
      }).then(res => {
        if (res.error_code === 0) {
          const data = res.data;
          this.information = {
            shopNow: self.merchantType === 0 ? data[0] : data.shops[0],
            shopList: self.merchantType === 0 ? data : data.shops,
          };
        }
      });
    },
    shopChange(item) {
      this.information.shopNow = item;
    },
  },
};
</script>

<style lang="less">
.merchant-data-wrap {
  overflow-y: auto;
  background-color: inherit;
}
.merchant-data-main {
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #8c8c8c;
    transform: translate(-50%, -50%);
  }
  .btns .el-button.active {
    background: @color-primary;
    border-color: @color-primary;
    color: #fff;
  }
  .exchange-btns {
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-button.active {
      font-weight: 600;
      color: @color-primary;
    }
    .el-button {
      position: relative;
      border: none;
      border-right: 1px solid #e5e5e5;
      &:hover {
        border-color: #e5e5e5 !important;
      }
      .el-date-editor {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        opacity: 0;
      }
    }
  }

  .storey-title {
    line-height: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid @border-color-main;
    .left {
      font-size: 18px;
      font-weight: 600;
    }
    .exchange-btns {
      margin-left: 16px;
    }
  }
  .storey-title + .data-module .title-medium {
    margin-top: 16px;
  }
  .title-medium {
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0 16px 0;
  }
  .data-module {
    .data-list {
      display: flex;
      justify-content: space-between;
      .data-item-1:last-child {
        margin-right: 0;
      }
      .data-item-2:last-child:after {
        content: none;
      }
    }
    .data-item-1 {
      padding: 16px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid @border-color-main;
      margin-right: 16px;
      .iconfont {
        font-size: 40px;
        margin-bottom: 8px;
      }
      .label {
        margin-bottom: 8px;
        color: @color-text-regular;
      }
      .value {
        font-weight: 600;
        font-size: 20px;
      }
    }
    .data-item-2 {
      position: relative;
      flex: 1;
      .data-item-content {
        padding: 18px 30%;
        display: flex;
        flex-direction: column;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 120px;
        border-left: 1px solid @border-color-main;
      }
      .text {
        margin-bottom: 8px;
        color: @color-text-regular;
      }
      .value {
        margin-bottom: 16px;
      }
      .value,
      .percent {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}
</style>
