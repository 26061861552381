const listPageMixin = {
  data() {
    return {
      refresh: false,
      refreshNow: false,
      activeSourceTabIndex: 0,
      tableSelectsQuery: {},
    };
  },
  computed: {
    sourceTabs() {
      return this.$store.state.shopSource;
    },
    shopSourceObj() {
      return this.$store.state.shopSourceObj;
    },
    activeSourceTab() {
      return this.sourceTabs[this.activeSourceTabIndex];
    },
    sourceName() {
      const chanel = this.sourceTabs[this.otherQuery.source];
      return this.$_.get(chanel, 'source_name', '');
    },
    source_name() {
      const chanel = this.shopSourceObj[this.otherQuery.chanel];
      return this.$_.get(chanel, 'source_name', '');
    },
    otherQuery() {
      return this.tableSelectsQuery;
    },
  },
  provide() {
    return {
      // 搜索栏enter键
      tableSelectsKeyDown: () => {
        this.searchBtn();
      },
    };
  },
  methods: {
    refreshList() {
      this.refresh = !this.refresh;
    },
    refreshNowList() {
      this.refreshNow = !this.refreshNow;
    },
    // 点击搜索按钮
    searchBtn() {
      this.refresh = new Array(10)
        .fill()
        .map(v =>
          'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'.charAt(Math.floor(Math.random() * 48))
        )
        .join('');
    },
    // 切换渠道来源tab
    handlerSwitchSource(tab) {
      this.activeSourceTabIndex = tab.index;
      this.searchBtn();
    },
  },
};

export default listPageMixin;
