<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>我的商品</span>
      <el-button style="float: right; padding: 3px 0" type="text">
        {{ userInfo.third_name }}
      </el-button>
    </div>
    <div class="">
      <div class="flex-right mb20">
        <div class="flex-right">
          <el-button class="c-bd-primary" size="small" @click="handleShowAddGoodsDialog">
            提交商品
          </el-button>
        </div>
      </div>
      <ListTable
        :columns="columns"
        road="cpProductList"
        :otherQuery="otherQuery"
        :refreshNow="refreshNow"
        ref="listTable"
        :selection="false"
        @callbackFun="callbackFun"
        :callback="true"
        v-show="tableData.data.length > 0"
      >
        <template v-slot:index="datas">
          <span>{{ datas.$index + 1 }}</span>
        </template>
        <template v-slot:goods="datas">
          <div class="align flex-left goods-detail mr20">
            <img :src="datas.row.pic" alt="" />
            <div class="ml10">
              <div class="goods-title text-ellipsis mb10">{{ datas.row.product_name }}</div>
            </div>
          </div>
        </template>
        <template v-slot:status="datas">
          <span>
            {{
              datas.row.status === '1'
                ? '审核中'
                : datas.row.status === '2'
                ? '审核通过'
                : '审核失败'
            }}
          </span>
        </template>
        <template v-slot:operation="datas">
          <el-button type="text" @click="viewGoods(datas.row)">
            {{ datas.row.status === '3' ? '重新提交' : '查看' }}
          </el-button>
        </template>
      </ListTable>
      <div v-show="tableData.data.length == 0" class="empty">
        <img src="https://12cang.oss-cn-hangzhou.aliyuncs.com/public/imgs/empty.svg" alt="" />
        <p>
          当前还未提交商品，请
          <span @click="handleShowAddGoodsDialog">提交商品</span>
          ~
        </p>
      </div>
      <GoodsEdit v-model="showEditDialog" :extra="editDialogData" :onOk="refreshNowList" />
    </div>
  </el-card>
</template>

<script>
import listPageMixin from '@/mixin/listPage';
import GoodsEdit from './diaLog/GoodsEdit';
export default {
  mixins: [listPageMixin],
  components: {
    GoodsEdit,
  },
  data() {
    return {
      showEditDialog: false,
      editDialogData: {},
      tableData: {
        data: [],
        paging: { pageSize: 1000, total: 1000 },
      },
      columns: [
        {
          label: '序号',
          slot: 'index',
        },
        {
          slot: 'goods',
          label: '商品信息',
          minWidth: 300,
        },
        {
          prop: 'brand_name',
          label: '品牌',
        },
        {
          prop: 'created_at',
          label: '提交时间',
        },
        {
          slot: 'status',
          label: '状态',
        },
        {
          slot: 'operation',
          label: '操作',
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    callbackFun(data) {
      this.tableData = data;
    },
    handleShowAddGoodsDialog() {
      this.showEditDialog = true;
      this.editDialogData = {
        title: '提交商品',
        formData: {
          product_name: null,
          img: [],
          product_url: null,
          rate: null,
          rate_price: null,
          show_price: null,
          price: null,
          cp_category_id: null,
          brand_name: null,
          sale_num: null,
        },
        type: 'add',
      };
    },
    viewGoods(row) {
      this.showEditDialog = true;
      this.editDialogData = {
        title: row.status === '3' ? '重新提交商品' : '查看' + '商品',
        formData: row,
        type: row.status === '3' ? 'againAdd' : 'view',
      };
    },
    // 获取数据列表
    getCategoryList() {
      this.$get({
        road: 'cpProductCategory',
        version: 'v0',
      }).then(res => {
        if (res.error_code === 0) {
          this.$store.commit('SET_SELECTPOOLCATEGORY', Object.values(res.data));
        }
      });
    },
    // 启用/禁用设置
    brandAble(id, is_able) {
      const that = this;
      const query = {
        id,
        is_able,
      };
      const str = is_able === 1 ? '启用' : '禁用';
      this.$confirm(`确定要${str}此品牌吗！`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        that
          .$get({
            road: 'brandAble',
            version: 'v0',
            query,
          })
          .then(res => {
            if (res.error_code === 0) {
              that.$message.success(`品牌${str}成功`);
              that.getList();
            }
          });
      });
    },
    // 品牌排序
    brandSort(type, index, row) {
      // 0上移，1下移
      const query = {
        ex_id: this.tableData.data[index + (type ? 1 : -1)].id,
        id: row.id,
      };
      this.$get({
        road: 'brandSort',
        query,
        version: 'v0',
      }).then(res => {
        if (res.error_code === 0) {
          this.getList();
        }
      });
    },
    // 删除品牌
    brandDel(id) {
      const that = this;
      this.$confirm('确定要删除此品牌吗！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        that
          .$get({
            road: 'brandDel',
            query: { id },
            version: 'v0',
          })
          .then(res => {
            if (res.error_code === 0) {
              that.getList();
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.empty {
  padding-top: 30px;
  text-align: center;
  padding-bottom: 100px;
  img {
    width: 200px;
  }
  p {
    margin-top: 20px;
  }
  span {
    color: #476cf2;
    cursor: pointer;
  }
}
.flex-left.goods-detail {
  line-height: 20px;
  .goods-title {
    max-height: 40px;
    color: @color-text-primary;
  }
  .source-info {
    color: #606266;
    font-size: 12px;
  }
  img {
    width: 90px;
    height: 90px;
  }
  .tag {
    display: inline-block;
    color: #ff7a3a;
    background: #ffebe1;
    padding: 0px 10px;
    border-radius: 15px;
    font-size: 12px;
  }
}
</style>
