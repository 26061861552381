<template>
  <div class="merchant-data-header bffffff between">
    <div class="title">
      <img
        width="200"
        src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/brand/merchant-data-title.png"
        alt=""
      />
    </div>
    <div class="name">
      <el-dropdown trigger="click" @command="shopChange" @visible-change="visibleChange">
        <span class="el-dropdown-link" :class="{ active: dropdown }" style="cursor:pointer;">
          {{ $_.get(information, 'shopNow.shop_name') }}
          <i v-if="showDropdown" class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="shop-menu">
          <el-dropdown-item
            v-for="item in information.shopList || []"
            :key="item.id"
            :command="item"
          >
            {{ item.shop_name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <i
        class="iconfont ml16"
        :class="[isFullScreen ? 'icon-quanping1' : 'icon-quanping']"
        @click="handleFullScreen"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    information: {
      type: Object,
    },
    isFullScreen: {
      type: Boolean,
    },
    handleFullScreen: {
      type: Function,
    },
    shopChange: {
      type: Function,
    },
  },
  data() {
    return {
      dropdown: false,
    };
  },
  computed: {
    showDropdown() {
      return this.information.shopList.length > 1;
    },
  },
  methods: {
    visibleChange(status) {
      this.dropdown = status;
    },
  },
};
</script>

<style lang="less" scoped>
.merchant-data-header {
  margin-bottom: 18px;
  line-height: 32px;
  .title {
    font-family: YouSheBiaoTiHei;
    font-size: 24px;
  }
  .name {
    font-weight: 600;
    span {
      font-size: 18px;
    }
    i {
      cursor: pointer;
      color: #8c8c8c;
    }
  }
  .el-dropdown-link i {
    transition: all 0.3s;
  }
  .el-dropdown-link.active i {
    transform: rotate(-180deg);
  }
}
.shop-menu {
  margin-top: 0;
  padding: 15px 0;
  .el-dropdown-menu__item {
    font-size: 16px;
    padding: 0 15px;
    line-height: 36px;
  }
}
</style>
