<template>
  <div class="merchant-data-shop bffffff">
    <div class="storey-title between">
      <div class="left">商品数据</div>
      <div class="right between">
        <div class="exchange-btns flex">
          <el-button
            plain
            size="small"
            class="btn button-plain--default"
            :class="{ active: activeDataIndex === index }"
            v-for="(item, index) in dataTypeOptions"
            :key="item.id"
            @click="exchange(index, item.type === 'normal')"
          >
            {{ item.label }}
            <el-date-picker
              v-model="item.model"
              :type="item.type"
              v-if="item.type !== 'normal'"
              @change="exchange(index, true)"
            ></el-date-picker>
          </el-button>
        </div>
      </div>
    </div>
    <div class="data-module mb24">
      <div class="title-medium between mt8">
        <div class="title" style="line-height:32px;">销量排行TOP10</div>
        <div class="btns flex">
          <el-button
            plain
            size="small"
            class="btn button-plain--default"
            :class="{ active: activeSalesIndex === index }"
            v-for="(item, index) in salesTypeOptions"
            :key="item.id"
            @click="activeSalesIndex = index"
          >
            {{ item.label }}
          </el-button>
        </div>
      </div>
      <div class="data-list">
        <div v-for="item in sourceData" :key="item.label" class="data-item-1">
          <span class="label">{{ item.name }}</span>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
    </div>
    <ListTable
      dataBy="send"
      :showIndex="true"
      :selection="false"
      :columns="columns"
      :showPagination="false"
      :propsTableData="propsTableData"
    >
      <template v-slot:goods="datas">
        <div class="align flex-left goods-detail mr20">
          <img :src="datas.row.pic_url" alt="" />
          <div class="ml10">
            <div class="goods-title text-ellipsis">{{ `${datas.row.title}` }}</div>
          </div>
        </div>
      </template>
    </ListTable>
  </div>
</template>

<script>
import listPageMixin from '@/mixin/listPage';
import merchantConfig from '@/data/merchantData';
export default {
  mixins: [listPageMixin],
  props: {
    information: {
      type: Object,
    },
  },
  data() {
    return {
      dataTypeOptions: merchantConfig.goods.dataType,
      salesTypeOptions: merchantConfig.goods.salesType,
      activeDataIndex: 1,
      activeSalesIndex: 0,
      columns: [
        {
          width: 20,
        },
        {
          slot: 'goods',
          label: '商品信息',
          width: 500,
        },
        {
          prop: 'category',
          label: '一级类目',
        },
        {
          prop: 'market_price',
          label: '售价',
          formatter: val => val + '元',
        },
        {
          prop: 'order_count',
          label: '销量',
        },
        {
          prop: 'total_amount',
          label: '销售额',
          formatter: val => val + '元',
        },
      ],
      goodsData: {},
    };
  },
  computed: {
    propsTableData() {
      return { data: this?.goodsData?.ranking || [], paging: { total: 100 } };
    },
    sourceData() {
      const data = this.$_.get(this.goodsData, 'unit_price', {});
      const arr = Object.keys(data).reduce((acc, key) => {
        const v = {
          name: (key === 'all' ? '总体' : this.shopSourceObj[key].source_name) + '客单价(元）',
          value: data[key],
        };
        if (key === 'all') {
          acc.unshift(v);
        } else {
          acc.push(v);
        }
        return acc;
      }, []);
      return arr;
    },
    goodsDataType() {
      return this.dataTypeOptions[this.activeDataIndex].value;
    },
    goodsSourceType() {
      return this.salesTypeOptions[this.activeSalesIndex].value;
    },
    merchantType() {
      return this.$store.state.merchantType;
    },
  },
  watch: {
    activeSalesIndex() {
      this.getData();
    },
    'information.shopNow.id': {
      handler(newValue) {
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    exchange(index, sym) {
      if (sym) {
        this.activeDataIndex = index;
        this.$nextTick(() => {
          this.getData();
        });
      }
    },
    getData() {
      const { model, type } = this.dataTypeOptions[this.activeDataIndex];
      if (!model && type !== 'normal') return;
      const date_begin_time = model ? this.$moment(model).unix() : '';
      this.$get({
        road: this.merchantType === 0 ? 'brainGoods' : 'jybrainGoods',
        query: {
          shop_id:
            this.merchantType === 0
              ? this.information.shopNow.id
              : this.information.shopNow.shop_id,
          date_type: this.goodsDataType,
          source_type: this.goodsSourceType,
          date_begin_time,
        },
        version: 'v0',
      }).then(res => {
        if (res.error_code === 0) {
          this.goodsData = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.flex-left.goods-detail {
  line-height: 20px;
  .goods-title {
    max-height: 40px;
    color: @color-text-primary;
  }
  img {
    width: 90px;
    height: 90px;
  }
}
</style>
