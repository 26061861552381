<template>
  <div class="home">
    <component v-bind:is="currentTabComponent"></component>
  </div>
</template>

<script>
import SelectPool from '../brand/selectPool/index';
import MerchanDate from '../brand/merchantData/index';
export default {
  components: { SelectPool, MerchanDate },
  mixins: [],
  data() {
    return {
      currentTabComponent: '',
    };
  },
  created() {
    if (!this.merchantType) {
      this.$store.dispatch('getAccountInfo');
    } else {
      if (this.merchantType === 0 || this.merchantType === 1) {
        this.currentTabComponent = 'MerchanDate';
      } else {
        this.$router.push('/select-pool');
        this.currentTabComponent = 'SelectPool';
      }
    }
  },
  computed: {
    merchantType() {
      return this.$store.state.merchantType;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    merchantType(newValue, oldValue) {
      if (newValue === 0 || newValue === 1) {
        this.currentTabComponent = 'MerchanDate';
      } else {
        this.$router.push('/select-pool');
        this.currentTabComponent = 'SelectPool';
      }
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
