const mixin = {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    // 其他配置参数
    extra: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
    },
    onOk: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
  },
  data() {
    return {
      submiting: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit('change', false);
      this.onCancel && this.onCancel();
      if (typeof this.closeCallBack === 'function') {
        this.closeCallBack && this.closeCallBack();
      }
    },
  },
};

export default mixin;
